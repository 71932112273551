
import Widget from '@/components/Widget/Widget.vue'
import { Component, Ref } from 'vue-property-decorator'
import ReportView from '@/models/ReportView'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import DataTable from '@/components/DataTable/index.vue'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import report_table_fields from './report-home-table-fields'

@Component({
  components: {
    Widget,
    IconAction,
    SearchInput,
    DataTable,
  },
})
export default class ReportViewHome extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  public view: ReportView = new ReportView()

  public sort_by: string = 'created_at'

  public sort_desc: boolean = true

  public ready: boolean = true

  public query: string[] = []

  public page_size: number = 25

  public page: number = 1

  public records: number = 0

  public loading: boolean = false

  // dataTable field filters
  public fieldFilters: any = {}

  public modal: any = {
    delete: false,
  }

  public fields: any = []

  public query_settings: any = {
    custom_fields: [],
    company_fields: [
      {
        name: 'company',
        key: 'company_id',
      },
      {
        name: 'user',
        key: 'owner_id',
        type: 'user',
      },
    ],
  }

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public views(context: any) {
    this.loading = true
    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)
    this.syncFilters()
    return ReportView.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: [...context.filter, ...field_filters],
    }).then(result => {
      this.records = result.records
      this.loading = false

      return result.data
    })
  }

  public confirmDelete(view: ReportView) {
    this.view = view
    this.modal.delete = true
  }

  public deleteView() {
    this.view.delete().then(() => {
      this.dataTable.refresh()
    })
  }

  public createView() {
    this.$router.push('/app/view')
  }

  public editView(id: string) {
    this.$router.push(`/app/view/${id}`)
  }

  public created() {
    this.records = this.views.length
  }

  public mounted() {
    this.loadFilters()
    this.fields = report_table_fields
  }

  // Store session filters in VUEX
  public syncFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'report_views',
      data: { query: this.query, fieldFilters: this.fieldFilters },
    })
  }

  // Load filters from VUEX if present
  public loadFilters() {
    const system = getModule(SystemtModule)
    system.getFilter('report_views').then((filter: any) => {
      if (filter) {
        this.query = filter.query
        this.fieldFilters = filter.fieldFilters
      }
      this.ready = true
    })
  }

  // Clear VUEX filters state
  public clearFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'report_views',
      data: null,
    })
  }

  // Refresh dataTable
  public refresh() {
    this.dataTable.refresh()
  }

  // Reset filters
  public resetFilters() {
    this.fieldFilters = {}
    this.query = []
    this.clearFilters()
    this.refresh()
  }
}
