
import { Component, Prop } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import ReportView from '@/models/ReportView'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import Inventory from '@/models/Inventory'
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import PublisherPicker from '@/components/PublisherPicker/PublisherPicker.vue'
import UserPicker from '@/components/UserPicker/UserPicker.vue'
import InsertionOrderPicker from '@/components/InsertionOrderPicker/InsertionOrderPicker.vue'
import InsertionOrderItemPicker from '@/components/InsertionOrderItemPicker/InsertionOrderItemPicker.vue'
import ViewModel from '@/models/ViewModel'

@Component({
  components: {
    Widget,
    SelectPicker,
    IconAction,
    FormInput,
    CompanyPicker,
    PublisherPicker,
    UserPicker,
    InsertionOrderPicker,
    InsertionOrderItemPicker,
  },
})
export default class ReportViewEdit extends ViewModel {
  @Prop()
  public id!: string

  public view: ReportView = new ReportView()

  public title: string = 'Create View'

  public loading = false

  public busy = false

  public get inventory_options() {
    return Inventory.module.type_options
  }

  public get publisher_options() {
    return Inventory.module.publisher_options
  }

  public get device_options() {
    return Inventory.module.device_name_options
  }

  public created() {
    const { query } = this.$route

    if (this.id) {
      this.loading = true
      this.busy = true
      ReportView.get(this.id).then(o => {
        if (o instanceof ReportView) {
          this.view = o

          this.title = 'Edit View'
        } else {
          this.view.owner_id = this.user.id
          this.view.company_id = this.user.company_id
        }
        this.loading = false
        this.busy = false
      })
    } else if (query.from && typeof query.from === 'string') {
      this.loading = true
      this.busy = true
      ReportView.get(query.from).then(o => {
        if (o instanceof ReportView) {
          this.view = ReportView.toObject(o)

          this.view.id = null
        }

        this.view.owner_id = this.user.id
        this.view.company_id = this.user.company_id
        this.loading = false
        this.busy = false
      })
    } else {
      this.view.owner_id = this.user.id
      this.view.company_id = this.user.company_id
    }
  }

  public cancel() {
    this.$router.push({ name: 'ReportViews' })
  }

  public onSubmit() {
    this.loading = true

    this.view
      .save()
      .then(response => {
        this.loading = false
        if (response.status == 200) {
          this.$router.push({ name: 'ReportViews' })
        }
      })
      .catch(() => {
        this.loading = false
      })
  }
}
