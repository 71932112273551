export default [
  {
    key: 'name',
    label: 'Name',
    sortable: true,
    class: 'text-center',
    show: true,
  },
  {
    key: 'created_at',
    label: 'Created At',
    sortable: true,
    class: 'text-center',
    show: true,
  },
  {
    key: 'updated_at',
    label: 'Update At',
    sortable: true,
    class: 'text-center',
    show: false,
  },
  {
    key: 'action',
    label: 'Action',
    sortable: false,
    class: 'text-center',
    show: true,
  },
]
